@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family:'Ubuntu';
    font-style:normal;
    font-weight:300;
    src:url('./assets/fonts/Ubuntu/Ubuntu-Light.ttf')
}
@font-face {
    font-family: 'Ubuntu';
    font-style: italic;
    font-weight: 300;
    src: url('./assets/fonts/Ubuntu/Ubuntu-LightItalic.ttf')
}
@font-face {
    font-family: 'Ubuntu';
    font-style: normal;
    font-weight: 400;
    src: url('./assets/fonts/Ubuntu/Ubuntu-Regular.ttf')
}
@font-face {
    font-family: 'Ubuntu';
    font-style: italic;
    font-weight: 400;
    src: url('./assets/fonts/Ubuntu/Ubuntu-Italic.ttf')
}
@font-face {
    font-family: 'Ubuntu';
    font-style: normal;
    font-weight: 500;
    src: url('./assets/fonts/Ubuntu/Ubuntu-Medium.ttf')
}
@font-face {
    font-family: 'Ubuntu';
    font-style: italic;
    font-weight: 500;
    src: url('./assets/fonts/Ubuntu/Ubuntu-MediumItalic.ttf')
}
@font-face {
    font-family: 'Ubuntu';
    font-style: normal;
    font-weight: 700;
    src: url('./assets/fonts/Ubuntu/Ubuntu-Bold.ttf')
}
@font-face {
    font-family: 'Ubuntu';
    font-style: italic;
    font-weight: 700;
    src: url('./assets/fonts/Ubuntu/Ubuntu-BoldItalic.ttf')
}

html {
    background-color: #F9FAFB;
    scroll-behavior: smooth;
    font-family: 'Ubuntu', monospace;
}